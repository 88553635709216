export const REQUEST_INIT_SESSION = "API_REQUEST_INIT_SESSION";
export const RECEIVE_INIT_SESSION = "API_RECEIVE_INIT_SESSION";

export const REQUEST_ME = "API_REQUEST_ME";
export const RECEIVE_ME = "API_RECEIVE_ME";

export const REQUEST_POLICY = "API_REQUEST_POLICY";
export const RECEIVE_POLICY = "API_RECEIVE_POLICY";

export const REQUEST_CODE_STATS = "API_REQUEST_CODE_STATS";
export const RECEIVE_CODE_STATS = "API_RECEIVE_CODE_STATS";

export const REQUEST_NEW_USER = "API_REQUEST_NEW_USER";
export const RECEIVE_NEW_USER = "API_RECEIVE_NEW_USER";
export const RESET_NEW_USER = "API_RESET_NEW_USER";

export const RECEIVE_PAYWALL_PAYMENT_WITH_FAUCET =
  "RECEIVE_PAYWALL_PAYMENT_WITH_FAUCET";
export const REQUEST_PAYWALL_PAYMENT_WITH_FAUCET =
  "REQUEST_PAYWALL_PAYMENT_WITH_FAUCET";

export const UPDATE_USER_PAYWALL_STATUS = "UPDATE_USER_PAYWALL_STATUS";

export const REQUEST_CHANGE_USERNAME = "API_REQUEST_CHANGE_USERNAME";
export const RECEIVE_CHANGE_USERNAME = "API_RECEIVE_CHANGE_USERNAME";

export const REQUEST_CHANGE_PASSWORD = "API_REQUEST_CHANGE_PASSWORD";
export const RECEIVE_CHANGE_PASSWORD = "API_RECEIVE_CHANGE_PASSWORD";

export const REQUEST_VERIFY_NEW_USER = "API_REQUEST_VERIFY_NEW_USER";
export const RECEIVE_VERIFY_NEW_USER = "API_RECEIVE_VERIFY_NEW_USER";

export const REQUEST_USER = "API_REQUEST_USER";
export const RECEIVE_USER = "API_RECEIVE_USER";

export const REQUEST_PROPOSAL_BILLING = "API_REQUEST_PROPOSAL_BILLING";
export const RECEIVE_PROPOSAL_BILLING = "API_RECEIVE_PROPOSAL_BILLING";

export const REQUEST_LOGIN = "API_REQUEST_LOGIN";
export const RECEIVE_LOGIN = "API_RECEIVE_LOGIN";

export const KEY_MISMATCH = "KEY_MISMATCH";

export const REQUEST_RESEND_VERIFICATION_EMAIL =
  "API_REQUEST_RESEND_VERIFICATION_EMAIL";
export const RECEIVE_RESEND_VERIFICATION_EMAIL =
  "API_RECEIVE_RESEND_VERIFICATION_EMAIL";
export const RESET_RESEND_VERIFICATION_EMAIL =
  "API_RESET_RESEND_VERIFICATION_EMAIL";

export const REQUEST_LOGOUT = "API_REQUEST_LOGOUT";
export const RECEIVE_LOGOUT = "API_RECEIVE_LOGOUT";
export const RECEIVE_CMS_LOGOUT = "API_RECEIVE_CMS_LOGOUT";

export const REQUEST_USER_INVENTORY = "REQUEST_USER_INVENTORY";
export const RECEIVE_USER_INVENTORY = "RECEIVE_USER_INVENTORY";

export const REQUEST_PROPOSAL = "API_REQUEST_PROPOSAL";
export const RECEIVE_PROPOSAL = "API_RECEIVE_PROPOSAL";

export const REQUEST_RECORD_COMMENTS = "API_REQUEST_RECORD_COMMENTS";
export const RECEIVE_RECORD_COMMENTS = "API_RECEIVE_RECORD_COMMENTS";

export const REQUEST_NEW_PROPOSAL = "API_REQUEST_NEW_PROPOSAL";
export const RECEIVE_NEW_PROPOSAL = "API_RECEIVE_NEW_PROPOSAL";

export const REQUEST_EDIT_PROPOSAL = "API_REQUEST_EDIT_PROPOSAL";
export const RECEIVE_EDIT_PROPOSAL = "API_RECEIVE_EDIT_PROPOSAL";

export const REQUEST_NEW_COMMENT = "API_REQUEST_NEW_COMMENT";
export const RECEIVE_NEW_COMMENT = "API_RECEIVE_NEW_COMMENT";

export const REQUEST_EDIT_COMMENT = "API_REQUEST_EDIT_COMMENT";
export const RECEIVE_EDIT_COMMENT = "API_RECEIVE_EDIT_COMMENT";

export const REQUEST_SETSTATUS_PROPOSAL = "API_REQUEST_SETSTATUS_PROPOSAL";
export const RECEIVE_SETSTATUS_PROPOSAL = "API_RECEIVE_SETSTATUS_PROPOSAL";

export const REQUEST_SET_BILLING_STATUS = "API_REQUEST_SET_BILLING_STATUS";
export const RECEIVE_SET_BILLING_STATUS = "API_RECEIVE_SET_BILLING_STATUS";

export const REQUEST_BILLING_STATUS_CHANGES =
  "API_REQUEST_BILLING_STATUS_CHANGES";
export const RECEIVE_BILLING_STATUS_CHANGES =
  "API_RECEIVE_BILLING_STATUS_CHANGES";

export const REQUEST_START_VOTE = "API_REQUEST_START_VOTE";
export const RECEIVE_START_VOTE = "API_RECEIVE_START_VOTE";
export const REQUEST_START_RUNOFF_VOTE = "API_REQUEST_START_RUNOFF_VOTE";
export const RECEIVE_START_RUNOFF_VOTE = "API_RECEIVE_START_RUNOFF_VOTE";
export const REQUEST_UPDATED_KEY = "API_REQUEST_UPDATED_KEY";
export const RECEIVE_UPDATED_KEY = "API_RECEIVE_UPDATED_KEY";

export const REQUEST_VERIFIED_KEY = "API_REQUEST_VERIFIED_KEY";
export const RECEIVE_VERIFIED_KEY = "API_RECEIVE_VERIFIED_KEY";

export const REQUEST_LIKE_COMMENT = "API_REQUEST_LIKE_COMMENT";
export const RECEIVE_LIKE_COMMENT = "API_RECEIVE_LIKE_COMMENT";
export const RECEIVE_LIKE_COMMENT_SUCCESS = "API_RECEIVE_LIKE_COMMENT_SUCCESS";

export const REQUEST_CENSOR_COMMENT = "API_REQUEST_CENSOR_COMMENT";
export const RECEIVE_CENSOR_COMMENT = "API_RECEIVE_CENSOR_COMMENT";

export const REQUEST_LIKED_COMMENTS = "API_REQUEST_LIKED_COMMENTS";
export const RECEIVE_LIKED_COMMENTS = "API_RECEIVE_LIKED_COMMENTS";

export const REQUEST_EDIT_USER = "API_REQUEST_EDIT_USER";
export const RECEIVE_EDIT_USER = "API_RECEIVE_EDIT_USER";

export const REQUEST_MANAGE_USER = "API_REQUEST_MANAGE_USER";
export const RECEIVE_MANAGE_USER = "API_RECEIVE_MANAGE_USER";

export const REQUEST_PROPOSAL_VOTE_RESULTS =
  "API_REQUEST_PROPOSAL_VOTE_RESULTS";
export const RECEIVE_PROPOSAL_VOTE_RESULTS =
  "API_RECEIVE_PROPOSAL_VOTE_RESULTS";

export const REQUEST_PROPOSALS_VOTE_SUMMARY =
  "API_REQUEST_PROPOSALS_VOTE_SUMMARY";
export const RECEIVE_PROPOSALS_VOTE_SUMMARY =
  "API_RECEIVE_PROPOSALS_VOTE_SUMMARY";

export const REQUEST_BATCH_PROPOSAL_SUMMARY =
  "API_REQUEST_BATCH_PROPOSAL_SUMMARY";
export const RECEIVE_BATCH_PROPOSAL_SUMMARY =
  "API_RECEIVE_BATCH_PROPOSAL_SUMMARY";

export const REQUEST_VOTES_DETAILS = "API_REQUEST_VOTES_DETAILS";
export const RECEIVE_VOTES_DETAILS = "API_RECEIVE_VOTES_DETAILS";

export const REQUEST_USER_SEARCH = "API_REQUEST_USER_SEARCH";
export const RECEIVE_USER_SEARCH = "API_RECEIVE_USER_SEARCH";

export const SAVE_DRAFT_PROPOSAL = "SAVE_DRAFT_PROPOSAL";
export const DELETE_DRAFT_PROPOSAL = "DELETE_DRAFT_PROPOSAL";
export const LOAD_DRAFT_PROPOSALS = "LOAD_DRAFT_PROPOSALS";

export const REQUEST_PROPOSAL_PAYWALL_DETAILS =
  "API_REQUEST_PROPOSAL_PAYWALL_DETAILS";
export const RECEIVE_PROPOSAL_PAYWALL_DETAILS =
  "API_RECEIVE_PROPOSAL_PAYWALL_DETAILS";

export const REQUEST_USER_PROPOSAL_CREDITS =
  "API_REQUEST_USER_PROPOSAL_CREDITS";
export const RECEIVE_USER_PROPOSAL_CREDITS =
  "API_RECEIVE_USER_PROPOSAL_CREDITS";

export const CSRF_NEEDED = "API_CSRF_NEEDED";

export const SHOULD_AUTO_VERIFY_KEY = "API_SHOULD_AUTO_VERIFY_KEY";

export const IDENTITY_IMPORTED = "IDENTITY_IMPORTED";

export const REQUEST_AUTHORIZE_VOTE = "REQUEST_AUTHORIZE_VOTE";
export const RECEIVE_AUTHORIZE_VOTE = "RECEIVE_AUTHORIZE_VOTE";

export const REQUEST_REVOKE_AUTH_VOTE = "REQUEST_REVOKE_AUTH_VOTE";
export const RECEIVE_REVOKE_AUTH_VOTE = "RECEIVE_REVOKE_AUTH_VOTE";

export const REQUEST_PROPOSAL_PAYWALL_PAYMENT =
  "REQUEST_PROPOSAL_PAYWALL_PAYMENT";
export const RECEIVE_PROPOSAL_PAYWALL_PAYMENT =
  "RECEIVE_PROPOSAL_PAYWALL_PAYMENT";

export const TOGGLE_CREDITS_PAYMENT_POLLING = "TOGGLE_CREDITS_PAYMENT_POLLING";
export const TOGGLE_CREDITS_PAYMENT_POLLING_REACHED_LIMIT =
  "TOGGLE_CREDITS_PAYMENT_POLLING_REACHED_LIMIT";
export const TOGGLE_PROPOSAL_PAYMENT_RECEIVED =
  "TOGGLE_PROPOSAL_PAYMENT_RECEIVED";

export const REQUEST_RESCAN_USER_PAYMENTS = "REQUEST_RESCAN_USER_PAYMENTS";
export const RECEIVE_RESCAN_USER_PAYMENTS = "RECEIVE_RESCAN_USER_PAYMENTS";
export const RESET_RESCAN_USER_PAYMENTS = "RESET_RESCAN_USER_PAYMENTS";

export const REQUEST_TOKEN_INVENTORY = "REQUEST_TOKEN_INVENTORY";
export const RECEIVE_VOTES_INVENTORY = "RECEIVE_VOTES_INVENTORY";
export const RECEIVE_RECORDS_INVENTORY = "RECEIVE_RECORDS_INVENTORY";

export const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";
export const RECEIVE_RESET_PASSWORD = "RECEIVE_RESET_PASSWORD";

export const REQUEST_VERIFY_RESET_PASSWORD = "REQUEST_VERIFY_RESET_PASSWORD";
export const RECEIVE_VERIFY_RESET_PASSWORD = "RECEIVE_VERIFY_RESET_PASSWORD";

export const REQUEST_PROPOSALS_BATCH = "REQUEST_PROPOSALS_BATCH";
export const RECEIVE_PROPOSALS_BATCH = "RECEIVE_PROPOSALS_BATCH";

// CMS
export const REQUEST_INVITE_USER = "REQUEST_INVITE_USER";
export const RECEIVE_INVITE_USER = "RECEIVE_INVITE_USER";

export const REQUEST_NEW_INVOICE = "API_REQUEST_NEW_INVOICE";
export const RECEIVE_NEW_INVOICE = "API_RECEIVE_NEW_INVOICE";

export const REQUEST_EDIT_INVOICE = "API_REQUEST_EDIT_INVOICE";
export const RECEIVE_EDIT_INVOICE = "API_RECEIVE_EDIT_INVOICE";

export const REQUEST_USER_INVOICES = "API_REQUEST_USER_INVOICES";
export const RECEIVE_USER_INVOICES = "API_RECEIVE_USER_INVOICES";

export const REQUEST_ADMIN_INVOICES = "API_REQUEST_ADMIN_INVOICES";
export const RECEIVE_ADMIN_INVOICES = "API_RECEIVE_ADMIN_INVOICES";

export const REQUEST_INVOICE = "API_REQUEST_INVOICE";
export const RECEIVE_INVOICE = "API_RECEIVE_INVOICE";

export const SAVE_DRAFT_INVOICE = "SAVE_DRAFT_INVOICE";
export const DELETE_DRAFT_INVOICE = "DELETE_DRAFT_INVOICE";
export const LOAD_DRAFT_INVOICES = "LOAD_DRAFT_INVOICES";

export const REQUEST_SETSTATUS_INVOICE = "API_REQUEST_SETSTATUS_INVOICE";
export const RECEIVE_SETSTATUS_INVOICE = "API_RECEIVE_SETSTATUS_INVOICE";

export const REQUEST_GENERATE_PAYOUTS = "API_REQUEST_GENERATE_PAYOUTS";
export const RECEIVE_GENERATE_PAYOUTS = "API_RECEIVE_GENERATE_PAYOUTS";

export const REQUEST_INVOICE_PAYOUTS = "API_REQUEST_INVOICE_PAYOUTS";
export const RECEIVE_INVOICE_PAYOUTS = "API_RECEIVE_INVOICE_PAYOUTS";

export const REQUEST_PAY_APPROVED = "API_REQUEST_PAY_APPROVED";
export const RECEIVE_PAY_APPROVED = "API_RECEIVE_PAY_APPROVED";

export const REQUEST_SPENDING_SUMMARY = "API_REQUEST_SPENDING_SUMMARY";
export const RECEIVE_SPENDING_SUMMARY = "API_RECEIVE_SPENDING_SUMMARY";

export const REQUEST_SPENDING_DETAILS = "API_REQUEST_SPENDING_DETAILS";
export const RECEIVE_SPENDING_DETAILS = "API_RECEIVE_SPENDING_DETAILS";

export const REQUEST_EXCHANGE_RATE = "API_REQUEST_EXCHANGE_RATE";
export const RECEIVE_EXCHANGE_RATE = "API_RECEIVE_EXCHANGE_RATE";

export const REQUEST_MANAGE_CMS_USER = "API_REQUEST_MANAGE_CMS_USER";
export const RECEIVE_MANAGE_CMS_USER = "API_RECEIVE_MANAGE_CMS_USER";

export const REQUEST_USER_SUBCONTRACTORS = "API_REQUEST_USER_SUBCONTRACTORS";
export const RECEIVE_USER_SUBCONTRACTORS = "API_RECEIVE_USER_SUBCONTRACTORS";

export const REQUEST_NEW_DCC = "API_REQUEST_NEW_DCC";
export const RECEIVE_NEW_DCC = "API_RECEIVE_NEW_DCC";

export const REQUEST_DCCS = "API_REQUEST_DCCS";
export const RECEIVE_DCCS = "API_RECEIVE_DCCS";

export const REQUEST_DCC = "API_REQUEST_DCC";
export const RECEIVE_DCC = "API_RECEIVE_DCC";

export const REQUEST_SUPPORT_OPPOSE_DCC = "API_REQUEST_SUPPORT_OPPOSE_DCC";
export const RECEIVE_SUPPORT_OPPOSE_DCC = "API_RECEIVE_SUPPORT_OPPOSE_DCC";

export const REQUEST_SET_DCC_STATUS = "API_REQUEST_SET_DCC_STATUS";
export const RECEIVE_SET_DCC_STATUS = "API_RECEIVE_SET_DCC_STATUS";

export const REQUEST_CMS_USERS = "API_REQUEST_CMS_USERS";
export const RECEIVE_CMS_USERS = "API_RECEIVE_CMS_USERS";

export const SAVE_DRAFT_DCC = "SAVE_DRAFT_DCC";
export const DELETE_DRAFT_DCC = "DELETE_DRAFT_DCC";
export const LOAD_DRAFT_DCCS = "LOAD_DRAFT_DCCS";

export const REQUEST_EDIT_CMS_USER = "API_REQUEST_EDIT_CMS_USER";
export const RECEIVE_EDIT_CMS_USER = "API_RECEIVE_EDIT_CMS_USER";

export const REQUEST_SET_TOTP = "API_REQUEST_SET_TOTP";
export const RECEIVE_SET_TOTP = "API_RECEIVE_SET_TOTP";

export const REQUEST_VERIFY_TOTP = "API_REQUEST_VERIFY_TOTP";
export const RECEIVE_VERIFY_TOTP = "API_RECEIVE_VERIFY_TOTP";

export const SET_HAS_TOTP = "SET_HAS_TOTP";

export const REQUEST_VOTES_BUNDLE = "API_REQUEST_VOTES_BUNDLE";
export const RECEIVE_VOTES_BUNDLE = "API_RECEIVE_VOTES_BUNDLE";
